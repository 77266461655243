export const URL_EndPoints: any = (QeueryParams: any, params?: any) => {
    const URLs: any =
    {
        // Ticket list api endpoints +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        getAvalabilitybySubscription: "getAvalabilitybySubscription",
        getAvalabilitybyAddress: "getAvalabilitybyAddress",
        assignMultipleCleanersWithSupervisior: "assignMultipleCleanersWithSupervisior",
        createCleanerTag: "createCleanerTag",
        getCleanerAttendance: "getCleanerAttendance",
        getleavereason: "getleavereason",
        getfillterlistforticket: "getfillterlistforticket",
        markcleanerleave: "markcleanerleave",
        gettimeslots: "gettimeslots",
        getCleanerList: "getCleanerList",
        getAdminList: "getAdminList",
        getSupervisorList: "getSupervisorList",
        getTicketSources: "getTicketSources",
        getTicketCategories: "getTicketCategories",
        changecustomertimeslot: "changecustomertimeslot",
        changeserviceday: "changeserviceday",
        getAllTicketsByCustomerId: "getAllTicketsByCustomerId",
        getAllTicketNewAdmin: "getAllTicketNewAdmin",
        getticketreplies: "getticketreplies",
        replyonticketbyadmin: "replyonticketbyadmin",
        notesonticketbyadmin: "notesonticketbyadmin",
        getTicketSubCategories: "getTicketSubCategories",
        createticketByAdmin: "createticketByAdmin",
        updateAssignedUsers: "updateAssignedUsers",
        updateTicketStatus: "updateTicketStatus",
        updateCategory: "updateCategory",
        updateSource: "updateSource",
        // Ticket list api endpoints +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        getXCleanerCustomers: "getXCleanerCustomers",
        getactivecleanerbycity: "getactivecleanerbycity",
        getAvalabilitybyCleaner: "getAvalabilitybyCleaner",
        allRenewalSubscriptionPagination: "allRenewalSubscriptionPagination",
        paynow: "subscription/paynow",
        getCustomerAllVehiclesRenewals: "getCustomerAllVehiclesRenewals",
        createCustomerPaymentLink: "createCustomerPaymentLink",
        updateSubscriptionRenewalReason: "updateSubscriptionRenewalReason",
        getplaystorereviews: "getplaystorereviews",
        // UpcomingStatsComponent api end pint +++++++++++++++++++++++++++++++++++++++++++++++++++++++
        getAllNotAssingedSubscription: "getAllNotAssingedSubscription",
        getActivePackageDetails: "getActivePackageDetails",
        allactivesubscriptionswithpaymentDatatablepagination: "allactivesubscriptionswithpaymentDatatablepagination",
        cleanerlist: "cleanerlist",
        getcleanerbankdetail: "getcleanerbankdetail",
        createCleanerBankDetails: "createCleanerBankDetails",
        getActiveCustomers: "getActiveCustomers",
        // Master sections api call here ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        getservices: "getservices",
        getReasons: "getReasons",
        getfuel: "getfuel",
        jobStatus: "jobStatus/getAll",
        getjobtypes: "getjobtypes",
        getAllOffers: "getAllOffers",
        getMasterReasons: "getMasterReasons",
        getpackages: "getpackages",
        getproblems: "getproblems",
        getservicesprices: "getservicesprices",
        getalltrainingtopic: "getalltrainingtopic",
        getalltshirt: "getalltshirt",
        getusertypes: "getusertypes",
        getbrand: "getbrand",
        getallvehiclecategory: "getallvehiclecategory",
        getmodel: "getmodel",
        getvehicle: "getvehicle",
        getsociety: "master/getsociety",
        addsociety: "master/addsociety",
        getcity: "getcity",
        getarea: "getarea",
        getClickupList: "getClickupList",
        getfillterlistforrating: "getfillterlistforrating",
        getallratings: "getallratings",
        allRenewalSubscriptionPaginationNew: "allRenewalSubscriptionPaginationNew",
        getSubscriptionRenewalReason: "getSubscriptionRenewalReason",
        getTicketRenewalSubscription: "getTicketRenewalSubscription",
        getCustomerStats: "getCustomerStats",
        getActiveCustomerlist: "getActiveCustomerlist",
        updateCleanerInactiveStatus: "updateCleanerInactiveStatus",
        updateCleanerReasonStatus: "updateCleanerReasonStatus",
        addTagCustomer: "addTagCustomerNew",
        onboardingPendingCleanerlist: "onboardingPendingCleanerlist",
        makeactivecleaner: "makeactivecleaner",
        getRefundStatus: "getRefundStatus",
        updateDueDate: "updateTicketDueDate",
        allInactiveSubscriptionPagination: "allInactiveSubscriptionPagination",
        master_getRatings: "master/getRatings",
        getTicketInactiveSubscription: "getTicketInactiveSubscription",
        markCleanerLeaveAbsent: "markCleanerLeaveAbsent",
        blockUnblockCustomer: "blockUnblockCustomer",
        getSubscriptionDataForExtend: "getSubscriptionDataForExtend",
        getjobsites: "getjobsites",
        getsubpackagedata: "getsubpackagedata",
        fuel: "fuel",
        addjobsite: "addjobsite",
        getservicePrice: "getservicePrice",
        getserviceDes: "getserviceDes",
        getfueltype: "getfuel",
        getAllCustomerListBySearch: "getAllCustomerListBySearch",
        pincodeStatusUpdate: "pincode/statusUpdate",
        getAppVersion: "getAppVersion",
        revertLeaveAbsent: "revertLeaveAbsent",
        assigncleanertoneworder: "assigncleanertoneworder",
        getTicketStatuses: "getTicketStatuses",
        getMasterTags: "getMasterTags",
        getMasterReasonsForSubscription: "getMasterReasonsForSubscription",
        updateNotAtHomeReason: "updateNotAtHomeReason",
        createUpdateMasterOffer: "createUpdateMasterOffer",
        addarea: "addarea",
        updateDueDateInactive: "updateDueDateInactive",
        makeRecuringRequest: "makeRecuringRequest",
        updateSubscriptionStatus: "updateSubscriptionStatus",
        checkRazorpayStatus: "checkRazorpayStatus",
        initateRefund: "initateRefund",
        getTransactions: "getTransactions",
        updateDuplicateVehicle: "updateDuplicateVehicle",
        getInvoiceAll: "getInvoiceAll",
        getSubscriptionsAll: "getSubscriptionsAll",
        generateinvoice: "generateinvoice",
        getAbsentLeaveList: "getAbsentLeaveList",
        addtagforcleaner: "addtagforcleaner",
        changecleanerofcustomer: "changecleanerofcustomer",
        createUpdateMasterTicketCategories: "createUpdateMasterTicketCategories",
        getWalletTransactions: "getWalletTransactions",
        generateWalletTransaction: "generateWalletTransaction",
        getRazorpayPayment: "getRazorpayPayment",
        allactivesubscriptionswithpaymentDatatablepaginationNew: "allactivesubscriptionswithpaymentDatatablepaginationNew",
        getAgentCallHistoryCount: "getAgentCallHistoryCount",
        getServiceDes: "getServiceDes",
        getServiceName: "getServiceName",
        getMasterNotificationTemplates: "getMasterNotificationTemplates",
        createMasterNotificationTemplate: "createMasterNotificationTemplate",
        allInactiveSubscriptionPaginationNew: "allInactiveSubscriptionPaginationNew",
        getFeedCategories: "getFeedCategories",
        getCleanerNotifications: "getCleanerNotifications",
        createCleanerNotification: "createCleanerNotification",
        updateMasterNotificationTemplate: "updateMasterNotificationTemplate",
        allFuturePauseSubscriptionPaginationNew: "allFuturePauseSubscriptionPaginationNew",
        getTicketSubcategories: "getTicketSubcategories",
        getFLowTypes: "getFLowTypes",
        createOrUpdateTicketSubCategory: "createOrUpdateTicketSubCategory",
        updateCustomerPhone: "updateCustomerPhone",
        vehicleDelete: "vehicleDelete",
        vehicleNumberUpdate: "vehicleNumberUpdate",
        markCompleteOndemandService: "markCompleteOndemandService",
        autopayFailedPaymentUpdate: "autopayFailedPaymentUpdate",
        getDailyWorkDataWithoutUpdate: "getDailyWorkDataWithoutUpdate",
        updateLeadsCount: "updateLeadsCount",
        clickToCallFromAdminToLead: "clickToCallFromAdminToLead",
        SendCustomerWhatsAppTemplateFromAdmin: "SendCustomerWhatsAppTemplateFromAdmin",
        updateApproveCoinUsage: "updateApproveCoinUsage",
        creditWalletCoins: "creditWalletCoins",
        subscriptionwaveoff: "subscriptionwaveoff",
        allVisotorListPagination: "allVisotorListPagination",
        getProspectTicket: "getProspectTicket",
        getMasterReasonForVisitors: "getMasterReasonForVisitors",
        updateVisitorStatus: "updateVisitorStatus",
        updateDueDateVisitor: "updateDueDateVisitor",
        getAllSocieties: "master/getAllSocieties",
        get_LatestWhatsAppMessageByNumber: "whatsapp/getLatestWhatsAppMessageByNumber",
        get_WhatsAppTemplate: "whatsapp/getWhatsAppTemplate",
        sendOpenSessionWhatsApp: "whatsapp/sendOpenSessionWhatsApp",
        sendTemplateWhatsApp: "whatsapp/sendTemplateWhatsApp",
        getAllSchemes: "getAllSchemes",
        sendTemplateNotification: "sendTemplateNotification",
        checkUserStatus: "checkUserStatus",
        getWhatsAppTemplate: "whatsapp/getWhatsAppTemplate",
        createUpdateMasterOfferForCustomer: "createUpdateMasterOfferForCustomer",
        getRenewalDataForSupervisor: "getRenewalDataForSupervisor",
        getTicketCampaigns: "getTicketCampaigns",
        getticketbycustomer: "getticketbycustomer",
        shift_WhatsAppMessageFromOneToAnother: "whatsapp/shiftWhatsAppMessageFromOneToAnother",
        cleanerlocationbycity: "cleanerlocationbycity",
        cleanerLocationByCityNewAdmin: "cleanerLocationByCityNewAdmin",
        create_NewTicketFromWhatsAppChat: "whatsapp/createNewTicketFromWhatsAppChat",
        getFirstFailedPaymentTransactions: "getFirstFailedPaymentTransactions",
        deactivatedCleanerlist: "deactivatedCleanerlist",
        assignToBulkTickets: "assignToBulkTickets",
        getprofile: "getprofile",
        ticketDashboard: "ticketDashboard",
        getTicketsDashboardData: "getTicketsDashboardData",
        ticketResponseDuration: "ticketResponseDuration",
        getTicketsCalendar: "getTicketsCalendar",
        saveAdminToken: "saveAdminToken",
        logout: "logout",
        getAllNotifications: "getAllNotifications",
        markSeenNotification: "markSeenNotification",
        regenerateJobs: "regenerateJobs",
        updateVisitorSource: "updateVisitorSource",
        updateVisitorSociety: "updateVisitorSociety",
        convertToFullCleaning: "convertToFullCleaning",


        getAllInventoryItems: "getAllInventoryItems",
        createInventoryItem: "createInventoryItem",
        updateMasterInventoryItem: "updateMasterInventoryItem/" + params?.id,
        getAllMasterOrderStatus: "getAllMasterOrderStatus",
        createMasterOrderStatus: "createMasterOrderStatus",
        updateMasterOrderStatus: "updateMasterOrderStatus/" + params?.id,
        getAllInventoryOrdersPagination: "getAllInventoryOrdersPagination",
        getAlladminlist: "getAlladminlist",
        register: "register",
        getAlJobTypes: "getAlJobTypes",
        allWelcomecallSubscriptionPaginationNew: "allWelcomecallSubscriptionPaginationNew",
        createInstanntCouponPercentage: "createInstanntCouponPercentage",
        addAndUpdateTrainingLocation: "addAndUpdateTrainingLocation",
        getAllTrainingLocation: "getAllTrainingLocation",
        getTrainingDetails: "getTrainingDetails",

        getAllIFrameDashboards: "getAllIFrameDashboards",
        getIframeDataByID: "getIframeDataByID",
        addAndUpdateIFrameURL: "addAndUpdateIFrameURL",
        getCallHistoryByCustomerIds: "getCallHistoryByCustomerIds",
        getChampAllDataByChampId: "getChampAllDataByChampId",
        getTransactionsByCustomerIds: "getTransactionsByCustomerIds",
        addQuestion: "addQuestion",
        editQuestion: "editQuestion",
        getQuestionsByNotificationTemplateId: "getQuestionsByNotificationTemplateId",
        getQuestionById: "getQuestionById",
        deleteCtOffer: "deleteCtOffer",
        getSubscriptionRazorpayOrders: "getSubscriptionRazorpayOrders",
        makeInactiveCleaner: "makeInactiveCleaner",
        getMasterReasonChampActiveDeactive: "getMasterReasonChampActiveDeactive",
        changeCleanerAttendanceStatus: "changeCleanerAttendanceStatus",
        getClickUpTaskComments: "getClickUpTaskComments",
        unassignBulkTickets: "unassignBulkTickets",
        sendBulkWhatsAppTemplate: "sendBulkWhatsAppTemplate",
        getAllLocationByJobID: "getAllLocationByJobID",
        getFunctions: "getFunctions",
        getAllImagesByCustomerId: "getAllImagesByCustomerId",
        getAppStoreReviewsFromDB: "getAppStoreReviewsFromDB",
        getPlayStoreReviewsFromDB: "getPlayStoreReviewsFromDB",
        getChampRequirement: "getChampRequirement",
        addChampRequirement: "addChampRequirement",
        getRequirementTypes: "getRequirementTypes",
        addRequirementType: "addRequirementType",
        updateRequirementType: "updateRequirementType",
        deleteChampRequirement: "deleteChampRequirement",
        getReferBySettings: "getReferBySettings",
        createNewScheme: "createNewScheme",
        getSchemes: "getSchemes",
        getCleanerTicketReplies: "getCleanerTicketReplies",
        notesOnChampTicketByAdmin: "notesOnChampTicketByAdmin",
        replyOnCleanerTicketByAdmin: "replyOnCleanerTicketByAdmin",
        updatesubscriptionaddress: "updatesubscriptionaddress",
        getcustomeraddress: "getcustomeraddress",
        updateSocietyAddress: "updateSocietyAddress",
        visitorListChampPagination: "visitorListChampPagination",
        getChampLeadTicket: "getChampLeadTicket",
        getAllTicketsByCleanerId: "getAllTicketsByCleanerId",
        updateChampVisitorStatus: "updateChampVisitorStatus",
        getCallHistoryByCleanerIds: "getCallHistoryByCleanerIds",
        updateChampPhone: "updateChampPhone",
        blockUnblockChamp: "blockUnblockChamp",
        getTrainingLocationDetail: "getTrainingLocationDetail",
        getAllTrainingLocations: "getAllTrainingLocations",
        initiateChampTraining: "initiateChampTraining",
        getChampLeadTrainingTaskList: "getChampLeadTrainingTaskList",
        getLatestChampWhatsAppMessageByNumber: "whatsapp/getLatestChampWhatsAppMessageByNumber",
        sendOpenSessionWhatsAppToChamp: "whatsapp/sendOpenSessionWhatsAppToChamp",
        sendTemplateWhatsAppToChamp: "whatsapp/sendTemplateWhatsAppToChamp",
        sendBulkWhatsAppTemplateToChamp: "whatsapp/sendBulkWhatsAppTemplateToChamp",
        getAllTrainers: "getAllTrainers",
        updateTicketOwnerAssigned: "updateTicketOwnerAssigned",
        getCleanerWeeklyPoints: "getCleanerWeeklyPoints",
        createNewCustomerLead: "createNewCustomerLead",
        createNewChampLead: "createNewChampLead",
        updateChampData: "updateChampData",
        getCleanerWeeklyPointsV2: "getCleanerWeeklyPointsV2",
        runSchemes: "runSchemes",
        visitorListChampPagination2: "visitorListChampPagination2",
        getDeductionReasons: "getDeductionReasons",
        deductWrongReporting: "deductWrongReporting",
        apporveLeaveAbsent: "apporveLeaveAbsent",
        getDeductionWrongMarkingReasons: "getDeductionWrongMarkingReasons",
        getSchemeParticipants: "getSchemeParticipants",
        removeFromScheme: "removeFromScheme",
        assignBulkFromScheme: "assignBulkFromScheme",
        allVisotorListPagination2: "allVisotorListPagination2",
        taskListPagination: "taskListPagination",
        getOwnerList: "getOwnerList",
        createSchemeActionForNonExpiryCron: "createSchemeActionForNonExpiryCron",
        refreshScheme: "refreshScheme",

        getActiveChampList: "getActiveChampList",
        updateTaskTrainer: "updateTaskTrainer",
        getMasterSubReason: "getMasterSubReason",
        updateChampSecurityDeposit: "updateChampSecurityDeposit",
        getSchemePayouts: "getSchemePayouts",
        clickToCallFromTicket: "clickToCallFromTicket",
        deductSecurityDeposits: "deductSecurityDeposits",
        removeDeductedWrongReporting: "removeDeductedWrongReporting",
        getSchemeDates: "getSchemeDates",
        getTrainerByTrainingLocation: "getTrainerByTrainingLocation",
        updateTaskData: "updateTaskData",
        updateCustomerAddress: "updateCustomerAddress",
        getsocietyinhurry: "getsocietyinhurry",
        updatesocietyinhurry: "updatesocietyinhurry",
        visitorListChampPagination2New: "visitorListChampPagination2New",
        getSettingData: "getSettingData",
        updateSettingData: "updateSettingData",
        getSubscriptionMonthAllV2: "getSubscriptionMonthAllV2",
        getSchemeRecord: "getSchemeRecord",
        getAllActiveSchemes: "getAllActiveSchemes",
        assignSchemeToCleaner: "assignSchemeToCleaner",
        giveCleanerOtherPoints: "giveCleanerOtherPoints",
        getAllMasterTags: "getAllMasterTags",
        updateTicketTags: "updateTicketTags",
        getRatingsV2: "getRatingsV2",
        createRNRTicket: "createRNRTicket",
        assignUserToReview: "assignUserToReview",
        replyToReview: "replyToReview",
        syncPlayStoreRating: "syncPlayStoreRating",
        syncAppStoreRating: "syncAppStoreRating",
        getpincodes: "getpincodes",
        createTag: "createTag",
        updateTag: "updateTag ",
        getWhatsAppNumber: "whatsapp/getWhatsAppNumber",
        createWATemplate: "whatsapp/createWATemplate ",
        updateWATemplate: "whatsapp/updateWATemplate ",
        showattendenceimages: "showattendenceimages",
        getAllAllowance: "getAllAllowance",
        assignAllowanceToCleaner: "assignAllowanceToCleaner",
        getCleanerAllowance: "getCleanerAllowance",
        bulkResetCustomerLeads: "bulkResetCustomerLeads",
        champApprovalListPagination: "champApprovalListPagination",
        activateOrReactivateChamp: "activateOrReactivateChamp",
        getChampDocuments: "getChampDocuments",
        login: "login",
        customerVehicleListPagination: "customerVehicleListPagination",
        updateArea: "updateArea",
        addCleanerWorkLocation: "addCleanerWorkLocation",
        addCleanerArea: "addCleanerArea",
        updateCleanerArea: "updateCleanerArea",
        userHashListPagination: "userHashListPagination",
        deleteHash: "deleteHash",
        createRenewalTask: "createRenewalTask",
        getAvalabilitybyChampId: "getAvalabilitybyChampId",
        getMasterOperationReasons: "getMasterOperationReasons",
        updateVehicleData: "updateVehicleData",
        bulkResetCustomerVehicle: "bulkResetCustomerVehicle",
        addFineOnWrongReporting: "addFineOnWrongReporting",
        updateSubscriptionData: "updateSubscriptionData",
        renewalTaskPagination: "renewalTaskPagination",
        assignBatch: "assignBatch",
        getBatches: "getBatches",
        updateCleanerBankDetails: "updateCleanerBankDetails",
        getMenuByUser: "menu/getMenuByUser",
        vehicleSubscriptionHistoryPagination: "vehicleSubscriptionHistoryPagination",
        getAllBatches: "getAllBatches",
        addNewBatch: "addNewBatch",
        updateBatch: "updateBatch",

        welcomeCall1TicketListPagination: "welcomeCall1TicketListPagination",
        getCampaignStatusForWelcomeCall: "getCampaignStatusForWelcomeCall",
        updateWelcomeTicketCampaignStatus: "updateWelcomeTicketCampaignStatus",
        welcomeCall2TicketListPagination: "welcomeCall2TicketListPagination",
        getCampaignStatusForWelcomeCall2: "getCampaignStatusForWelcomeCall2",
        updateWelcomeTicket2CampaignStatus: "updateWelcomeTicket2CampaignStatus",
        getWelcomeCallCustomerSentiment: "getWelcomeCallCustomerSentiment",
        updateWelcomeCallCustomerSentiment: "updateWelcomeCallCustomerSentiment",
        createRenewalTaskForNextDateBulk: "createRenewalTaskForNextDateBulk",
        getAllFrequencies: "getAllFrequencies",
        getAllCustomersList: "getAllCustomersList",
        getActiveCustomerlistRating: "v2/getactivecustomerlist",
        generateCleanerWeeklyReport: "generateCleanerWeeklyReport",
        getUniqueUserHashList: "getUniqueUserHashList",
        getDeleteReasonList: "getDeleteReasonList",
        updateUniqueUserHashRow: "updateUniqueUserHashRow",
        getPayoutReportStatus: "getPayoutReportStatus",
        changeWeeklyReportsStatus: "changeWeeklyReportsStatus",
        ticketDashboardData: "ticketDashboardData",
        getReqApprovalTrackerList: "getReqApprovalTrackerList",
        updateJobRatingsColumns: "updateJobRatingsColumns",
        getDeductionReasonsList: "getDeductionReasonsList",
        addRequestForReward: "addRequestForReward",
        updateRequestApprovalTrackerColumns: "updateRequestApprovalTrackerColumns",
        updateRequestForReward: "updateRequestForReward",
        getConditionalReqApprovalTrackerList: "getConditionalReqApprovalTrackerList",
        updateRenewalTaskListColumns: "updateRenewalTaskListColumns",
        getCleanerFineList: "getCleanerFineList",
        getCleanerRewardsList: "getCleanerRewardsList",
        getStaticFilterOptionsData: "getStaticFilterOptionsData",
        getChampsList: "getChampsList",
        updateChampReferral: "updateChampReferral",
        createChampReferralPaymentRequest: "createChampReferralPaymentRequest",
        updateChampColumns: "updateChampColumns",
        getPriorityOptions: "getPriorityOptions",
        updateChampRequirement: "updateChampRequirement",
        getChampRequirementWithChampCount: "getChampRequirementWithChampCount",
        getChampHiringDashboardData: "getChampHiringDashboardData",
        downloadFileFromS3: "downloadFileFromS3",
        readXLSXFileAndCreateCleaner: "readXLSXFileAndCreateCleaner",
        updateTicketOpeningTime: "updateTicketOpeningTime",
        updateTicketClosingTime: "updateTicketClosingTime",
        updateRequestTrackerListStatusInBulk: "updateRequestTrackerListStatusInBulk",
        approveChampRequirementInBulk: "approveChampRequirementInBulk",
        addCleanerAddressInBulk: "addCleanerAddressInBulk",
        mapChampRequirementsLocation: "mapChampRequirementsLocation",
        getJobStatuses: "getJobStatuses",
        getCustomerCallStatusList: "getCustomerCallStatusList",
        addBlocksSubArea: "addBlocksSubArea",
        getMapBlocksSubAreasList: "getMapBlocksSubAreasList",
        getMapBlocksList: "getMapBlocksList",
        getMasterSourceList: "getMasterSourceList",
        addMasterSource: "addMasterSource",
        updateMasterSource: "updateMasterSource",
        getMapBlocksSubAreasListForFilter: "getMapBlocksSubAreasListForFilter",
        getDeactivatedChampslist: "getDeactivatedChampslist",
        markTrainingTaskStatus: "markTrainingTaskStatus",
        updateSubArea: "updateSubArea",
        deleteSubArea: "deleteSubArea",
        getTaskTypeStatuses: "getTaskTypeStatuses",
        writeCommentOnTicketForAreaManager: "writeCommentOnTicketForAreaManager",
        updateAdminData: "updateAdminData",
        updateAdminCallStatus: "updateAdminCallStatus",
        getCustomersList: "getCustomersList",

        sendChampTrainingReminder: "sendChampTrainingReminder",
        getAreaManagerAccessAccountsList: "getAreaManagerAccessAccountsList",
        getWhatsAppTemplatesForListing: "whatsapp/getWhatsAppTemplatesForListing",
        toggleAreaManagerAccess: "toggleAreaManagerAccess",
        bulkDeleteChampRequirements: "bulkDeleteChampRequirements",
        updateUnActionedHiringTasksToMissed: "updateUnActionedHiringTasksToMissed ",

        getCustomerListForAgentTransfer: "getCustomerListForAgentTransfer",
        transferCustomerAgent: "transferCustomerAgent",
        createAreaManagerAccess: "createAreaManagerAccess",
        bulkAssignOwnerToTickets: "bulkAssignOwnerToTickets",
        getInactiveChampTicket: "getInactiveChampTicket",
        updateDueDateOldChamp: "updateDueDate",
        updateCallbackAgent: "updateCallbackAgent",
        bulkUpdateCallbackAgent: "bulkUpdateCallbackAgent",
        getChampCallStatusList: "getChampCallStatusList",
        deleteArea: "deleteArea",
        getActivePaidSubscriptionList: "getActivePaidSubscriptionList",
        updateChampColumnsInBulk: "updateChampColumnsInBulk",
        updateCustomerWhatsAppNumber: "updateCustomerWhatsAppNumber",
        updateChampWhatsAppNumber: "updateChampWhatsAppNumber",
        getDocumentTypes: "getDocumentTypes",
        uploadChampDocument: "uploadChampDocument",
        editChampDocument: "editChampDocument",
        getactivecleanerforneworder: "getactivecleanerforneworder",
        updateVehicleImage: "updateVehicleImage",
        getWhatsAppGroups: "whatsapp/getWhatsAppGroups",
        getWhatsappClientStatus: "whatsapp/getWhatsappClientStatus",
        getWhatsAppGroupsForFilter: "whatsapp/getWhatsAppGroupsForFilter",
        updateRequestForRewardInBulk: "updateRequestForRewardInBulk",
        getChampReferralList: "getChampReferralList",
        getTicketList: "getTicketList",
        createChampReferralRequestInBulk: "createChampReferralRequestInBulk",
        getChampBankDetails: "getChampBankDetails",
        updateChampBankDetails: "updateChampBankDetails",
        getRenewalSubscriptionList: "getRenewalSubscriptionList",
        getJobVerificationHistory: "getJobVerificationHistory",
        updateAttendenceColumns: "updateAttendenceColumns",
        verifyBankAccount: "easebuzz/wire/verifyBankAccount",
        verifyVPA: "easebuzz/wire/verifyVPA",
        addAccountDetail: "easebuzz/wire/addAccountDetail",
        createBankAccountBeneficiary: "easebuzz/wire/createBankAccountBeneficiary",
        newContact: "easebuzz/wire/newContact",
        initiateTransfer: "easebuzz/wire/initiateTransfer",
        initiateTransferDirectToChamp: "easebuzz/wire/initiateTransferDirectToChamp",
        getEasebuzzTransactionHistory: "easebuzz/wire/getEasebuzzTransactionHistory",
        getChampPaymentTransactionHistory: "getChampPaymentTransactionHistory",
        getSingleCleanerWeeklyReports: "getSingleCleanerWeeklyReports",
        getMasterAdminUserList: "getMasterAdminUserList",
        getPayoutAmountsByStatus: "getPayoutAmountsByStatus",
        getActiveVirtualAccountsBalance: "easebuzz/wire/getActiveVirtualAccountsBalance",
        getCleanersPayoutReports: "getCleanersPayoutReports",
        generateSpecificCleanerWeeklyReport: "generateSpecificCleanerWeeklyReport",
        getNewDailyDashboardData: "getNewDailyDashboardData",
        getNewDailyDashboardChampData: "getNewDailyDashboardChampData",
        saveLanguage: "saveLanguage",
        getChampListNewDailyDashboard: "getChampListNewDailyDashboard",
        allChampsJobs: "allChampsJobs",
        allChampsJobsV2: "allChampsJobsV2",
        getJobEditHistory: "getJobEditHistory",
        getExotelCampaignContactListDetailsInBulk: "getExotelCampaignContactListDetailsInBulk",
        addContactsAndCreateCampaignList: "addContactsAndCreateCampaignList",
        getMasterNotes: "getMasterNotes",
        updateDeactivationReason: "updateDeactivationReason",
        getMasterNotesForListing: "getMasterNotesForListing",
        getNotesCategories: "getNotesCategories",
        createMasterNote: "createMasterNote",
        getAllTrainingLocationClient: "getAllTrainingLocationClient",
        deleteTrainingLocation: "deleteTrainingLocation",
        getNotesByCategory: "getNotesByCategory",
        generateCleanerMonthlyReport: "generateCleanerMonthlyReport",
        revertNAHAdmin: "revertNAHAdmin",
        holdOrReleaseCleanerPaymentOnCondition: "holdOrReleaseCleanerPaymentOnCondition",
        addFineOrRewardBasedOnAvgRating: "addFineOrRewardBasedOnAvgRating",
        revertCleanerJobLeft: "revertCleanerJobLeft",
        revertTrainingTask: "revertTrainingTask",
        getLoansList: "getLoansList",
        assignLoanToCleaner: "assignLoanToCleaner",
        approveLoan: "approveLoan",
        updateLoanStatus: "updateLoanStatus",
        blockUnblockCustomerLogin: "blockUnblockCustomerLogin",
        getChampDetailsOfCampaignListSid: "getChampDetailsOfCampaignListSid",
        getChampDetailsOfCampaignListFile: "getChampDetailsOfCampaignListFile",
        searchableSubAreasList: "searchableSubAreasList",
        generateSpecificCleanerMonthlyReport: "generateSpecificCleanerMonthlyReport",
        getSpecificLoanTransactions: "getSpecificLoanTransactions",
        addFAQ: "addFAQ",
        getAllFAQs: "getAllFAQs",
        getFAQById: "getFAQById",
        getAllFAQsCategories: "getAllFAQsCategories",
        // updateFAQStatus: "updateFAQStatus",
        updateFAQColumns: "updateFAQColumns",
        addFAQCategory: "addFAQCategory",
        getSmartFilters: "getSmartFilters",
        addSmartFilter: "addSmartFilter",
        updateSmartFilter: "updateSmartFilter",
        togglePinUnpinFAQ: "togglePinUnpinFAQ",
        resetLoanAndRegenerateTransaction: "resetLoanAndRegenerateTransaction",
        generateAttendance: "generateAttendance",
        getAllAMWalletData: "getAllAMWalletData",
        getPayoutTimeline: "getPayoutTimeline",
        getTicketsCountBasedOnCategory: "getTicketsCountBasedOnCategory",
        getTicketsCountBasedOnSource: "getTicketsCountBasedOnSource",
        getAvailabilityTimeslots: "getAvailabilityTimeslots",
        addCleanerAvailabilities: "addCleanerAvailabilities",
        getAreaManagerActiveChamps: "getAreaManagerActiveChamps",
        addBuddyPreferences: "addBuddyPreferences",
        transferLoanAmount: "transferLoanAmount",
        getChampRequirementsWithChamps: "getChampRequirementsWithChamps",
        champHiringAutomation: "champHiringAutomation",
        updateChampRequirementsWithChampsColumns: "updateChampRequirementsWithChampsColumns",
        updateSubscriptionCustomerTimeslot: "updateSubscriptionCustomerTimeslot",
        getCustomerTimeslots: "getCustomerTimeslots",
        getAllTimeSlots: "getAllTimeSlots",
        markChampAsRejected: "markChampAsRejected",
        getAllPathList: "app/get",
        addUpdateJsonData: "app",
        updatePriorityBasedRequirements: "updatePriorityBasedRequirements",
        updateRequirementLeadsCount: "updateRequirementLeadsCount",
        getDeactivatedChampAddressDetail: "getDeactivatedChampAddressDetail",
        getWhatsAppTemplateSingle: "getWhatsAppTemplateSingle",
        getTicketsCountBasedOnSubCategory: "getTicketsCountBasedOnSubCategory",
        getAllAMCustomerData: "getAllAMCustomerData",
        champHiringAutomationApplyGlobalFilter: "champHiringAutomationApplyGlobalFilter",
        changeSubscriptionStartDate: "changeSubscriptionStartDate",
        resumeListPagination: "resumeListPagination",
        getLeadsCountBasedOnSource: "getLeadsCountBasedOnSource",
        getSourceVsLeadStatus: "getSourceVsLeadStatus",
        getCronHistoryList: "getCronHistoryList",
        getLeadsCountBasedOnSourceOrStatus: "getLeadsCountBasedOnSourceOrStatus",
        getAllRoles: "getAllRoles",
        getUserAllowedPermissions: "getUserAllowedPermissions",
        getRolePermissions: "getRolePermissions",
        getUserPermissions: "getUserPermissions",
        updateFeaturePermissions: "updateFeaturePermissions",
        getAgentCallOnOffHistory: "getAgentCallOnOffHistory",
        get24Hours: "get24Hours",
        assignPackageVariant: "assignPackageVariant",
        toggleServiceCharge: "toggleServiceCharge",
        getJobsDashboardData: "getJobsDashboardData",
        getPaymentsCountBasedOnFrequencyOrMonths: "getPaymentsCountBasedOnFrequencyOrMonths",
        getNoJobsSubscriptions: "getNoJobsSubscriptions",
        getUnSuccessfulSubscriptions: "getUnSuccessfulSubscriptions",
        getAgentAllotments: "getAgentAllotments",
        createOfferAllotmentForAgent: "createOfferAllotmentForAgent",
        getAgentAllotmentsPagination: "getAgentAllotmentsPagination",
        getSalesDataInTimeSeries: "getSalesDataInTimeSeries",
        getSalesRows: "getSalesRows",
        toggleLoginBlocked: "toggleLoginBlocked",
        provideAddOnOffer: "provideAddOnOffer",
        checkIfAgentAllowedToCloseDashboard: "checkIfAgentAllowedToCloseDashboard",
        updateCustomerInactiveData: "updateCustomerInactiveData",
        // updateVehicleData: "updateVehicleData",
        getVehicleCategories: "getVehicleCategories",
        getVehicleModels: "getVehicleModels",
        getVehicleBrands: "getVehicleBrands",
        getVehicleTypes: "getVehicleTypes",
        getVehicleDetail: "getVehicleDetail",
        getVehicleColors: "getVehicleColors",
    }

    if (QeueryParams?.To) {
        // if (QeueryParams?.To.includes("_")) { 
        //     let urls = URLs[QeueryParams?.To] 
        //     URLs[QeueryParams?.To] = `${urls + QeueryParams?.What}` 
        // } 
        URLs[QeueryParams?.To] = `${URLs[QeueryParams?.To] + QeueryParams?.What}`
    }
    return URLs
}
// URL_EndPoints()?.getleavereason
// URL_EndPoints(null)?.getTicketSources


