
type FunctionType = (...args: any[]) => any;

export const throttle = (func: FunctionType, limit: number): FunctionType => {
  let inThrottle: boolean;
  return function(this: any, ...args: any[]) {
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  };
};

export const getFullName = (data: {first_name: string, last_name: string}) => {
  const firstName = data?.first_name || ''
  const lastName = data?.last_name || ''
  return firstName || lastName ? (firstName + ' ' + lastName).trim() : ''
}