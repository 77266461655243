import { getName } from "../admins/adminUtils";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import AddOn from "./AddOn";
import PermissionContextProvider from "../admins/adminComponents/PermissionContextProvider";
import { adminMenuActionPermissionKeys } from "../admins/adminConstant";

export const AgentOffersListColumns = (champTrackData?: any) => [
    {
        id: 1,
        reorder: true,
        name: "ID",
        selector: (row: any) => row?.id ?? "NA",
        sortable: true,
        sortField: 'id',
        width: "80px"
    },
    {
        id: 2,
        reorder: true,
        name: "AGENT",
        cell: (row: any) => {
            return getName(row?.agentAllotedItemAdmin)
        },
        sortable: true,
        sortField: 'agentAllotedItemAdmin.first_name',
        width: "200px",
    },
    {
        id: 10,
        reorder: true,
        name: "ROLE",
        cell: (row: any) => row?.agentAllotedItemAdmin?.role,
        width: "120px",
        sortable: true,
        sortField: 'agentAllotedItemAdmin.role',
    },
    {
        id: 4,
        reorder: true,
        name: "VALUE",
        selector: (row: any) => row?.item_value ? row?.item_value : "NA",
        sortable: true,
        sortField: 'item_value',
        width: "120px"
    },
    {
        id: 5,
        reorder: true,
        name: "UNIT",
        selector: (row: any) => row?.unit ? row?.unit : "NA",
        sortable: true,
        sortField: 'unit',
        width: "120px"

    },
    {
        id: 13,
        reorder: true,
        name: "TYPE",
        selector: (row: any) => row?.item_type,
        sortable: true,
        sortField: 'type',
        width: "120px"

    },
    {
        id: 11,
        reorder: true,
        name: "QUANTITY",
        selector: (row: any) => row?.quantity ? row?.quantity : "NA",
        sortable: true,
        sortField: 'quantity',
        width: "100px"
    },
    {
        id: 6,
        reorder: true,
        name: "START DATE",
        selector: (row: any) => row?.start_date ? row?.start_date : "NA",
        sortable: true,
        sortField: 'start_date',
        width: "140px"
    },
    {
        id: 7,
        reorder: true,
        name: "END DATE",
        selector: (row: any) => row?.end_date ? row?.end_date : "NA",
        sortable: true,
        sortField: 'end_date',
        width: "140px"
    },
    {
        id: 8,
        reorder: true,
        name: "COUPON CREATED",
        selector: (row: any) => row?.coupons_created ?? 0,
        // sortable: true,
        // sortField: 'id',
        width: "140px"
    },
    {
        id: 9,
        reorder: true,
        name: "COUPON USED",
        selector: (row: any) => row?.coupons_used ?? 0,
        // sortable: true,
        // sortField: 'id',
        width: "140px"
    },
    {
        id: 10,
        reorder: true,
        name: "COUPON EXPIRED",
        selector: (row: any) => row?.coupons_expired ?? 0,
        width: "140px"
    },

    {
        id: 12,
        reorder: true,
        name: "ADD ON COUPON",
        selector: (row: any) => <AddOnCoupon row={row} />,
        width: "140px",
        // hideCols:"add-on-coupon-update"
    },

]



const AddOnCoupon = ({ row }: any) => {
    const [rowData, setRowData] = useState(row)
    useEffect(() => { setRowData(row) }, [row])
    const [show, setShow] = useState(false)
    const setUpatedData = (value: any) => {
        row.add_on = value
        setRowData(row)
    }

    return (
        <>
            <PermissionContextProvider keyName={adminMenuActionPermissionKeys["add-on-coupon-update"]}>
                <FontAwesomeIcon icon={faEdit} className="text-muted me-4" onClick={() => setShow(true)} />
            </PermissionContextProvider>

            {rowData?.add_on ?? 0}
            {
                show ? <AddOn
                    show={show}
                    setShow={setShow}
                    row={row}
                    setUpatedData={setUpatedData}
                /> : <></>
            }
        </>
    )
}

