import React, { useEffect, useState } from 'react'
import AdminTextBoxReuse from '../../../modules/admins/adminForms/adminFormComponents/AdminTextBoxReuse'
import { Button } from 'react-bootstrap'
import AdminSingleSelectFilter from '../../../modules/admins/adminForms/adminFormComponents/AdminSingleSelectFilter'
import { getRequest } from '../../Server/Requests'
import { URL_EndPoints } from '../../Server/URL_EndPoints'
import InactiveCampaignStatus from './actionOnCall/InactiveCampaignStatus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import ProspectStatus from './actionOnCall/ProspectStatus'
import AdminDueDateForm from '../../../modules/admins/adminComponents/AdminDueDateForm'

const ActionOnCall = ({ noticationCredential, customerDetailData }: any) => {
    let config: any = {
        prospect: {
            body: {
                campaign_status: "",
                due_date: "",
            },
            formApi: "formApi",
            listApi: "listApi",
        },
        inactive: {
            body: {
                campaign_status: "",
                due_date: "",
            },
            formApi: "formApi",
            listApi: "listApi",
        }
    }

    let currentConfig = config[noticationCredential?.noticationCredential]

    const [payload, setPayload] = useState(currentConfig?.payload)

    const [loader, setLoader] = useState(false)
    const [campaignStatusList, setCampaignStatusList] = useState([])

    const onChangeHandler = (event: any, name: string) => {
        setPayload({ ...payload, [name]: event ? event?.value : event })
    }

    const getListData = async (listApi: any) => {
        const response = await getRequest(URL_EndPoints()?.[listApi], setLoader)
        setCampaignStatusList(response?.data?.data)
    }

    useEffect(() => {
        getListData(currentConfig?.listApi)
    }, [currentConfig])

    const submitForm = () => {
        console.log('payload', payload);

    }

    const [updatDueDate, setUpdatDueDate] = useState(false)
    const [updateStatus, setUpdateStatus] = useState(false)

    return (
        <div className='mt-10 mb-10 d-flex justify-content-center align-items-center'>

            < div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0 me-4'>
                <FontAwesomeIcon icon={faEdit} className='text-muted me-4 fs-5 cursor-pointer' onClick={() => setUpdatDueDate(true)} />
                <span className='fw-bold fs-7 text-dark me-2'>Update Due Date </span>
            </div>

            < div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0'>
                <FontAwesomeIcon icon={faEdit} onClick={() => setUpdateStatus(true)} className='text-muted me-4 fs-5 cursor-pointer' />
                <span className='fw-bold fs-7 text-dark me-2'>Update Status </span>
            </div>


            {
                currentConfig == "inactive" ? <>
                    < div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0'>
                        <FontAwesomeIcon icon={faEdit} onClick={() => setUpdatDueDate(true)} className='text-muted me-4 fs-5 cursor-pointer' />
                        <span className='fw-bold fs-7 text-dark me-2'>Update Due Date </span>
                    </div>

                    < div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0'>
                        <FontAwesomeIcon icon={faEdit} onClick={() => setUpdatDueDate(true)} className='text-muted me-4 fs-5 cursor-pointer' />
                        <span className='fw-bold fs-7 text-dark me-2'>Update Status </span>
                    </div>
                </> : currentConfig == "propect" ? <>

                    < div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0'>
                        <FontAwesomeIcon icon={faEdit} onClick={() => setUpdatDueDate(true)} className='text-muted me-4 fs-5 cursor-pointer' />
                        <span className='fw-bold fs-7 text-dark me-2'>Update Due Date </span>
                    </div>
                    < div className='d-flex justify-content-start badge badge-secondary text-dark rounded-0'>
                        <FontAwesomeIcon icon={faEdit} onClick={() => setUpdatDueDate(true)} className='text-muted me-4 fs-5 cursor-pointer' />
                        <span className='fw-bold fs-7 text-dark me-2'>Update Campaign Status</span>
                    </div>
                </> : <></>
            }


            {
                updateStatus ? <InactiveCampaignStatus
                    show={updateStatus}
                    setShow={setUpdateStatus}
                    row={customerDetailData}
                /> : <></>
            }

            {
                updateStatus ? <ProspectStatus
                    show={updateStatus}
                    setShow={setUpdateStatus}
                    row={customerDetailData}
                /> : <></>
            }

            {/* update propect due date   */}
            {
                updatDueDate ? <AdminDueDateForm
                    show={updatDueDate}
                    setShow={setUpdatDueDate}
                    setUpdatedData={null}
                    prevInitialPayload={{
                        // ticket_history_id: storeRow?.tickets?.length > 0 ? (storeRow?.tickets[0].tickethistory?.length > 0 ? storeRow?.tickets[0].tickethistory[0]?.id : 0) : 0,
                        // ticket_id: storeRow?.tickets?.length > 0 ? storeRow?.tickets[0]?.id : 0,
                        // customerid: storeRow?.id,
                        // user_id: userid,
                        // prev_due_date: storeRow?.tickets?.length > 0 ? (storeRow?.tickets[0].tickethistory?.length > 0 ? storeRow?.tickets[0]?.last_date_resolution : '') : '',
                        // due_date: storeRow?.tickets?.length > 0 ? (storeRow?.tickets[0].tickethistory?.length > 0 ? storeRow?.tickets[0]?.last_date_resolution : '') : '',
                    }}
                    row={customerDetailData}
                    title="Due Date Update"
                    apiUrl="updateDueDateVisitor"
                    dueDateKeyName="due_date"
                    isDateTime={true}
                    isServerDueDate={false}
                    serverApiUrl="xxxxx"
                /> : <></>

            }


            {/* update inactive due date   */}
            {
                updatDueDate ? <AdminDueDateForm
                    show={updatDueDate}
                    setShow={setUpdatDueDate}
                    setUpdatedData={null}
                    prevInitialPayload={{
                        // ticket_history_id: storeRow?.tickets?.length > 0 ? (storeRow?.tickets[0].tickethistory?.length > 0 ? storeRow?.tickets[0].tickethistory[0]?.id : 0) : 0,
                        // ticket_id: storeRow?.tickets?.length > 0 ? storeRow?.tickets[0]?.id : 0,
                        // customerid: storeRow?.id,
                        // user_id: userid,
                        // prev_due_date: storeRow?.tickets?.length > 0 ? (storeRow?.tickets[0].tickethistory?.length > 0 ? storeRow?.tickets[0]?.last_date_resolution : '') : '',
                        // due_date: storeRow?.tickets?.length > 0 ? (storeRow?.tickets[0].tickethistory?.length > 0 ? storeRow?.tickets[0]?.last_date_resolution : '') : '',
                    }}
                    row={customerDetailData}
                    title="Due Date Update"
                    apiUrl="updateCustomerInactiveData"
                    dueDateKeyName="due_date"
                    isDateTime={true}
                    isServerDueDate={false}
                    serverApiUrl="xxxxx"
                /> : <></>

            }


        </div>
    )
}

export default ActionOnCall